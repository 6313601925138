import React, { FC, Fragment, useEffect, useState } from "react";
import { Card, Icon, Grid, Button, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import FirebaseFirestoreService from "../../../services/FirebaseFirestoreService";
import "./FoodView.css";
import { useSelector } from "react-redux";
import ModalFoodItem from "../../layouts/ModalFoodItem";
import Food from "../../../model/food";
import FoodItemRow from "./FoodItemRow";
import Select from "react-select";
import { colourStyles } from "../../layouts/SelectStyleTree";
import DropdownRestaurants from "../../layouts/DropdownRestaurants";
import { selectRestaurantId } from "../../../redux/userSlice";

interface FoodViewProps {
  id: string;
}

const FoodView: FC<FoodViewProps> = ({ id }) => {
  const { t } = useTranslation();

  let resId = useSelector(selectRestaurantId);
  if (id) resId = id;
  const [value, setValue] = useState<any[]>([]);
  const [modalOpen, changeModalOpen] = useState(false);
  const [listFood, setListFood] = useState<Food[]>([]);
  const [lastFood, setLastFood] = useState(null);
  const [firstFood, setFirstFood] = useState<any>(null);
  const [listCateTree, setListCateTree] = useState<any[]>([]);
  const [listCate, setListCate] = useState<any[]>([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (value.length === 0) {
      return;
    }
    if (resId === "") {
      return;
    }

    loadFoodItem();
  }, [resId, value, setListFood]);

  const loadFoodItem = async () => {
    FirebaseFirestoreService.getFoodItemsPeriod(
      resId,
      cateGoryQuery(),
      (querySnapshot: any) => {
        querySnapshot?.forEach((doc: any, index: number) => {
          if (index === querySnapshot?.length - 1) setLastFood(doc);
          if (index === 0) setFirstFood(doc);
        });
        setListFood(querySnapshot);
      },
      (error: any) => {
        console.log(error);
        console.log("table-list-item-get-fail");
      }
    );
  };

  useEffect(() => {
    if (resId === "") {
      return;
    }
    const fecthCate = async () => {
      const unsubscribe = await FirebaseFirestoreService.getCateTree(resId);
      setListCateTree(unsubscribe);
      const listCate = await FirebaseFirestoreService.getCategoryList(resId);
      setListCate(listCate);
    };
    fecthCate();
  }, [resId]);

  const handleChange = async (newValue: any, data: any) => {
    setValue(newValue);
  };

  const cateGoryQuery = () => {
    return value.map((item: any) => {
      return {
        value: item.value,
      };
    });
  };

  return (
    <div className={id ? "food-view" : ""}>
      {!id && <DropdownRestaurants />}
      {resId !== "" && (
        <Fragment>
          <Card fluid className="container-header">
            <Grid>
              <Grid.Row className="form-search">
                <Button color="instagram" onClick={() => changeModalOpen(true)}>
                  <Icon name="plus" />
                  {t("Food_Items.btn_add")}
                </Button>
                <Select
                  isSearchable={false}
                  className="tree-select"
                  isMulti={true}
                  options={listCateTree}
                  defaultValue={value}
                  placeholder="Pick Category"
                  styles={colourStyles}
                  onChange={handleChange}
                />
              </Grid.Row>
            </Grid>
          </Card>
          {modalOpen && (
            <ModalFoodItem
              cateGoryDefault={value}
              id_restaurant={resId}
              openModal={modalOpen}
              setOpenModal={changeModalOpen}
            />
          )}

          {listFood?.length > 0 ? (
            <>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell scope="col">
                      {t("Food_Items.table.name")}
                    </Table.HeaderCell>
                    <Table.HeaderCell id="hiddenMobile" scope="col" collapsing>
                      {t("Food_Items.table.image")}
                    </Table.HeaderCell>
                    <Table.HeaderCell scope="col">
                      {t("Food_Items.table.print_name")}
                    </Table.HeaderCell>
                    {/*<Table.HeaderCell id="hiddenMobile" scope="col">{t("Food_Items.table.print_name")}</Table.HeaderCell>*/}
                    <Table.HeaderCell scope="col">
                      {t("Food_Items.table.price")}
                    </Table.HeaderCell>
                    <Table.HeaderCell id="hiddenMobile" scope="col">
                      {t("Food_Items.table.category")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      id="hiddenMobile"
                      scope="col"
                      collapsing
                      textAlign="center"
                    >
                      {t("Food_Items.table.option")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      id="hiddenMobile"
                      scope="col"
                      collapsing
                      textAlign="center"
                    >
                      {t("Food_Items.table.topping")}
                    </Table.HeaderCell>
                    <Table.HeaderCell scope="col" collapsing textAlign="center">
                      {t("Food_Items.table.active")}
                    </Table.HeaderCell>
                    <Table.HeaderCell scope="col" collapsing textAlign="center">
                      {t("Food_Items.table.action")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {listFood.map((item: any) => (
                    <FoodItemRow
                      key={item.id}
                      id_restaurant={resId}
                      foodItem={item}
                      listCate={listCate}
                      loadFoodItem={loadFoodItem}
                    />
                  ))}
                </Table.Body>
              </Table>

              {page === 1 ? (
                <></>
              ) : (
                <Button
                  size="big"
                  color="grey"
                  floated="left"
                  icon="chevron left"
                  onClick={() => {
                    return FirebaseFirestoreService.getFoodItemsPeriodByPrevious(
                      resId,
                      cateGoryQuery(),
                      firstFood,
                      (querySnapshot: any) => {
                        const updatedGroceryItems = querySnapshot.map(
                          (doc: any, index: number) => {
                            if (index === querySnapshot.length - 1)
                              setLastFood(doc);
                            if (index === 0) setFirstFood(doc);
                            return { id: doc.id, ...doc };
                          }
                        );
                        setListFood(updatedGroceryItems);
                        setPage((state) => state - 1);
                      },
                      (error: any) => {
                        console.log(error);
                        console.log("table-list-item-get-fail");
                      }
                    );
                  }}
                />
              )}
              {listFood.length < 20 ? (
                <></>
              ) : (
                <Button
                  size="big"
                  color="grey"
                  floated="right"
                  icon="chevron right"
                  onClick={() => {
                    return FirebaseFirestoreService.getFoodItemsPeriodByNext(
                      resId,
                      cateGoryQuery(),
                      lastFood,
                      async (querySnapshot: any) => {
                        const updatedGroceryItems =
                          await querySnapshot.docs.map(
                            (doc: any, index: number) => {
                              if (index === querySnapshot.docs.length - 1)
                                setLastFood(doc);
                              if (index === 0) setFirstFood(doc);
                              return { id: doc.id, ...doc.data() };
                            }
                          );

                        if (updatedGroceryItems.length === 0) {
                          return;
                        }
                        setListFood(updatedGroceryItems);
                        setPage((state) => state + 1);
                      },
                      (error: any) => {
                        console.log(error);
                        console.log("table-list-item-get-fail");
                      }
                    );
                  }}
                />
              )}
            </>
          ) : (
            <div className="message">
              <h2>{t("FoodEdit.message")}</h2>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
export default FoodView;
